import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"

const RefPage = () => {
  useEffect(() => {
    navigate("/")
  }, [])

  return (
    <Router>
      <RefKey path={"/ref/:ref_key"} />
    </Router>
  )
}

const RefKey = props => {
  const { ref_key } = props
  useEffect(() => {
    if (ref_key?.length === 16) {
      localStorage.setItem("ref_key", ref_key)
    }
  }, [ref_key])
  return <div />
}
export default RefPage
